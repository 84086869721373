import Head from 'next/head';
import React from 'react';
import classNames from 'classnames';
import { FeatureFlagWidget } from '@frontend/shared-ui-kit/feature-flag-widget';

export function PublicLayout({ children }: { children: any }) {
  return (
    <>
      <Head>
        <title>Howl</title>
      </Head>

      <FeatureFlagWidget />
      <div className={'static-page h-full'}>
        <div
          className={classNames(
            'page-container h-full pb-safe',
            // `${this.moveContentRight() ? 'move-right' : 'move-left'}`,
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
}
