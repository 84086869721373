import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useActions, useEffects } from '@frontend/howl-web-app/overmind';
import { PublicLayout } from '../components/ui/layouts/PublicLayout';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getSession, useSession } from 'next-auth/react';
import { useLocalStorage } from '@mantine/hooks';

function IndexPage() {
  const router = useRouter();
  const { status } = useSession();
  const { getNextAuthStartingUrlPath } = useEffects().auth;

  const [signinRedirectUrl, setValue] = useLocalStorage<string | null>({
    key: 'signinRedirectUrl',
    defaultValue: null,
  });

  const redirectAfterLogin = async () => {
    try {
      const { redirect, ...rest } = router.query;
      const session = await getSession();
      if (session && session.user) {
        if (signinRedirectUrl) {
          router.push(signinRedirectUrl);
          setValue(null);
          return;
        } else {
          const path = getNextAuthStartingUrlPath(session);
          router.push(path);
        }
      } else {
        router.push('/signin');
      }
    } catch (e) {
      // TODO: implement log drain / global snackbar
    }
  };

  useEffect(() => {
    console.log('Session Status', status);
    if (status === 'unauthenticated') {
      const originalPath = window.location.pathname;
      window.location.href = `/signin${
        originalPath !== '/' ? `?redirect=${originalPath}` : ''
      }`;
    } else if (status === 'authenticated') {
      redirectAfterLogin();
    }
  }, [status]);

  return null;
}

IndexPage.Layout = PublicLayout;

export default IndexPage;
